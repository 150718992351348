<template>
  <v-container id="issue-tables" fluid tag="section">
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t(prop_title)"
      class="px-5 py-3"
    >
      <dialog-progress
        :dialog_message="dialog_message"
        :message="message"
        v-on:close="close"
      ></dialog-progress>
      <slot name="panel">
        <v-col>
          <v-select
            v-model="choose_urban"
            :items="urbans"
            item-text="name"
            item-value="id"
            :label="$t('issue.chooseUrban')"
            hide-details
            persistent-hint
            return-object
            filled
          ></v-select>
        </v-col>
        <v-col class="text-right">
          <v-btn
            color="primary"
            dark
            class="mx-2"
            @click="onRetrieveTranscribe()"
          >
            <v-icon left>mdi-pencil-plus</v-icon>
            {{ $t("plan.retrieve") }}
          </v-btn>
          <v-btn
            color="secondary"
            dark
            class="mx-2"
            @click="exportData()"
            v-if="choose_urban"
          >
            <v-icon left>mdi-pencil-plus</v-icon>
            {{ $t("plan.export-transcribe") }}
          </v-btn>
        </v-col>
      </slot>
      <!-- display 善打 -->
      <v-data-table
        :items="items"
        :headers="headers"
        hide-default-header
        v-if="items.length > 0"
      >
        <template v-slot:header="{ props }">
          <tbody>
            <tr class="ro1">
              <td rowspan="3" class="customer-header">
                <p>序號</p>
              </td>
              <td rowspan="3" class="customer-header">
                <p>所有權人/管理人</p>
              </td>
              <td colspan="9" class="customer-header">
                <p>土地</p>
              </td>
              <td colspan="12" class="customer-header">
                <p>建物</p>
              </td>
              <td rowspan="3" class="customer-header-address">
                <p>所有權人地址</p>
              </td>
            </tr>
            <tr class="ro2">
              <td rowspan="2" class="customer-header-medium">
                <p>鄉鎮市區</p>
              </td>
              <td rowspan="2" class="customer-header-medium">
                <p>地段</p>
              </td>
              <td rowspan="2" class="customer-header-medium">
                <p>小段</p>
              </td>
              <td rowspan="2" class="customer-header">
                <p>地號</p>
              </td>
              <td rowspan="2" class="customer-header">
                <p>&nbsp;土地面積(平方公尺)</p>
              </td>
              <td rowspan="2" class="customer-header">
                <p>權利範圍</p>
              </td>
              <td rowspan="2" class="customer-header">
                <p>&nbsp;持分面積(平方公尺)</p>
              </td>
              <td rowspan="2" class="customer-header">
                <p>總計面積(平方公尺)</p>
              </td>
              <td rowspan="2" class="customer-header">
                <p>土地佔比(%)</p>
              </td>
              <td rowspan="2" class="customer-header">
                <p>建號</p>
              </td>
              <td rowspan="2" class="customer-header-address">
                <p>建物門牌</p>
              </td>
              <td rowspan="2" class="customer-header-address">
                <p>坐落基地</p>
              </td>
              <td rowspan="2" class="customer-header">
                <p>主建面積(平方公尺)</p>
              </td>
              <td rowspan="2" class="customer-header">
                <p>附建面積(平方公尺)</p>
              </td>
              <td colspan="3" class="customer-header">
                <p>共同使用部分</p>
              </td>
              <td rowspan="2" class="customer-header">
                <p>權利範圍</p>
              </td>
              <td rowspan="2" class="customer-header">
                <p>持分面積(平方公尺)</p>
              </td>
              <td rowspan="2" class="customer-header">
                <p>總計面積(平方公尺)</p>
              </td>
              <td rowspan="2" class="customer-header">
                <p>建物佔比(%)</p>
              </td>
            </tr>
            <tr class="ro3">
              <td class="customer-header">
                <p>&nbsp;面積</p>
              </td>
              <td class="customer-header">
                <p>權利範圍</p>
              </td>
              <td class="customer-header">
                <p>&nbsp;持分面積</p>
              </td>
            </tr>
          </tbody>
        </template>

        <template v-slot:item="{ item, index }">
          <tr v-for="(detail, row_ind) in item.data">
            <td
              v-for="(header, header_index) in headers"
              :key="header_index"
              v-if="allowTD(detail, header.value)"
              :rowspan="calculateRowSpan(detail, header.value)"
              :style="{
                backgroundColor: detail.SN % 2 === 0 ? '#FFF5CE' : '',
                // border:
                //   calculateRowSpan(detail, header.value) > 1 &&
                //   header_index > 0 &&
                //   header_index != 20
                //     ? 'thin solid rgba(0, 0, 0, 0.12)'
                //     : '',
                border: 'thin solid rgba(0, 0, 0, 0.12)',
              }"
            >
              {{ displayItem(detail[header.value], header.type) }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import CommonUtil from "@/utils/commonutil.js";
import UrbanMixin from "../common/UrbanMixin.vue";
export default {
  props: {
    prop_title: {
      type: String,
      default: "title.Transcribe",
    },
    prop_emit: {
      type: Boolean,
      default: false,
    },
  },
  data: (vm) => ({
    dialog_message: false,
    message: {
      title: "",
      text: "",
    },
    options: {},
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [5, 10, 20],
    },
    headers: [
      {
        text: "序號",
        align: "start",
        value: "SN",
      },
      { text: "所有權人/管理人", value: "Owner" },
      { text: "行政區", value: "Dist" },
      { text: "段", value: "Sec" },
      { text: "小段", value: "SubSec" },
      { text: "地號", value: "LN" },
      { text: "土地面積(平方公尺)", value: "LA" },
      { text: "土地權利範圍", value: "LRR" },
      { text: "土地持分面積(平方公尺)", value: "LSA", type: "float" },
      { text: "土地總計面積(平方公尺)", value: "TLA", type: "float" },
      { text: "土地佔比(%)", value: "LS", type: "percentage" },
      { text: "建號", value: "BN" },
      { text: "建物門牌", value: "BA", width: "200px" },
      { text: "坐落基地", value: "BL" },
      { text: "主建面積(平方公尺)", value: "MBA", type: "float" },
      { text: "附建面積(平方公尺)", value: "ABA", type: "float" },
      { text: "共同使用部分_面積", value: "CA", type: "float" },
      { text: "共同使用部分_權利範圍", value: "CARR" },
      { text: "共同使用部分_持分面積", value: "CSA" },
      { text: "建物權利範圍", value: "BRR" },
      { text: "建物持分面積(平方公尺)", value: "BSA", type: "float" },
      { text: "建物總計面積(平方公尺)", value: "TBA", type: "float" },
      { text: "建物佔比(%)", value: "BS", type: "percentage" },
      { text: "所有權人地址", value: "OA", width: "200px" },
    ],
    row_span_headers: [
      "SN",
      "Owner",
      "TLA",
      // "BN",
      // "BA",
      // "BN",
      // "MBA",
      // "BL",
      // "ABA",
      "LS",

      "TBA",
      "BS",
      "OA",
    ],
  }),
  mixins: [UrbanMixin],
  components: {
    datetime: Datetime,
  },
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    ...mapMutations(["setTitle"]),
    close() {
      this.dialog_message = false;
    },
    onTranscribeFinal() {
      if (this.prop_emit) {
        this.$emit("onTranscribeFinal");
      } else {
        console.log("onTranscribeFinal");
      }
      this.dialog_message = false;
    },
    onRetrieveTranscribe() {
      this.message = {
        title: this.$t("plan.transcribe"),
        text: "取得資料中...",
      };
      this.dialog_message = true;
      // delay 1 second to call this.getTranscribe();
      setTimeout(() => {
        this.getTranscribe();
      }, 500);
    },
    exportTranscribe() {
      this.onExporting();
      const url = `${process.env.VUE_APP_SERVER_URL}/plan/transcribe/export/`;
      const currentObj = this;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
        responseType: "blob",
        params: {
          urban: this.choose_urban.id,
        },
      };
      this.axios
        .get(url, config)
        .then(function (response) {
          let filename = `${currentObj.choose_urban.name}_繕打.xlsx`;
          currentObj.forceFileDownload(response, filename);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          this.onExportDone();
        });
    },
    onExporting() {
      this.message = {
        title: this.$t("plan.transcribe"),
        text: "匯出資料中...",
      };
      this.dialog_message = true;
    },
    onExportDone() {
      this.dialog_message = false;
    },
    forceFileDownload(response, filename) {
      console.log("forceFileDownload:", filename);
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
    },
    displayItem(value, type) {
      switch (type) {
        case "percentage":
          return this.showPercent(value);
        case "float":
          return this.showFloat(value);
        default:
          return value;
      }
    },
    showPercent(value) {
      if (value == null) {
        return "";
      }
      try {
        let val = parseFloat(value.toFixed(4));
        return (val * 100).toFixed(2) + "%";
      } catch (error) {
        return value;
      }
    },
    showFloat(value) {
      if (value == null || value == "") {
        return "";
      }
      try {
        return value.toFixed(2);
      } catch (error) {
        return value;
      }
    },
    allowTD(item, key) {
      if (!this.row_span_headers.includes(key)) {
        return true;
      }
      // if owner is not null or empty return true
      if (item.Owner != null && item.Owner != "") {
        return true;
      }
      return false;
    },
    calculateRowSpan(item, key) {
      // if key is not in row_span_headers, return 1
      // console.log("calculateRowSpan", item);
      if (!this.row_span_headers.includes(key)) {
        return 1;
      }
      // if item.Owner is null or empty, return 1
      if (item.Owner == null || item.Owner == "") {
        return 1;
      }
      // console.log("calculateRowSpan", item.Owner, key, item.rowsapn, "..");
      // if item.rowsapn is not null, return item.rowsapn else return 1
      return item.rowsapn ? item.rowsapn : 1;
    },
    exportData() {
      if (this.choose_urban == null) {
        // this.dialog_message = true;

        return;
      }
      if (this.prop_emit == true) {
        this.$emit("export");
        return;
      }
      this.exportTranscribe();
    },
  },
  watch: {
    options: {
      handler() {
        this.getTranscribe();
      },
    },
  },
  mounted() {
    this.getUrbanRenewal();
    this.setTitle(this.$i18n.t("title.Transcribe"));
  },
};
</script>

<style lang="sass" scoped>
.theme--light.v-list-item:hover::before
    opacity: 0.4
.v-list-item--link:before
    background-color: #ff9800

.customer-header
  color: #00791e !important
  border: thin solid rgba(0, 0, 0, 0.12)

.customer-header-medium
  @extend .customer-header
  width: 100px
  min-width: 100px

.customer-header-address
  @extend .customer-header
  width: 200px
  min-width: 200px
</style>
